import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { PencilIcon, UserIcon } from "@heroicons/react/20/solid";
import AuthContext from "../context/AuthContext";
import myConfig from "../configs/config";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextField from '@mui/material/TextField';
import { createTheme, Skeleton, ThemeProvider } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Bounce, ToastContainer, toast } from "react-toastify";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 6,
    whiteSpace: 'nowrap',
    width: 1,
  });

export function ReadBlog() {
    const [blogs, setBlogs] = useState(null); // Default to null to differentiate between loading and empty state
    const { authTokens, user, logoutUser } = useContext(AuthContext);
    const { BlogId } = useParams();
    const [is_edit, set_isEdit] = useState(false);
    const [value, setValue] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [title, setTitle] = useState('');
    const navigate = useNavigate();

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const [preview, setPreview] = useState(null);
    
        
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file)); // Set the preview URL
        }
    };

    useEffect(() => {
        const getBlog = async () => {
            console.log("Getting individual blog", BlogId);
            const blogUrl = `${myConfig.CRU_URL}/account/blog/${BlogId}/`;
            try {
                const response = await axios.get(blogUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authTokens.access}`,
                    },
                });
                console.log(response.data);
                setBlogs(response.data);
                setTitle(response.data.title);
                setValue(response.data.description);
            } catch (error) {
                console.error("Error fetching blogs", error);
            }
        };

        if (authTokens && BlogId) { // Check that authTokens and BlogId are available
            getBlog();
        }else{
            logoutUser();
        }
    }, [authTokens, BlogId]); // Add BlogId to the dependency array

    // Edit Blog
    const handleEdit = () => {
        const blogUrl = `${myConfig.CRU_URL}/account/blog/`;
        console.log(title, value, selectedFile);
        if ( title === '' || value === '' || BlogId === null) {
            alert('Please fill all fields');
            return;
        }
        let form_data = new FormData();
        form_data.append('title', title);
        form_data.append('description', value);
        if(selectedFile){
            form_data.append('image', selectedFile);
        }
        form_data.append('id', BlogId);
        form_data.append('user', String(authTokens.access));
        console.log(form_data);
        toast.progress('Processing! Please wait.');
        axios({
            baseURL: blogUrl,
            method: 'PUT',
            headers: {
                'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${authTokens.access}`
            },
            data: form_data,
        })
        .then((res) => {
            console.log(res.data);
            if(res.status === 200){
                console.log("Blog Finally Done")
                toast.success('Blog Edited 👍', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
                navigate('/blogs');
            }else if(res.statusText === 'Unauthorized'){
                logoutUser();
            }

        })
        .catch((error) => {
            if (error.response.status === 401) {
                // handle 401 error here
                logoutUser();
            }
        })

    }

    if (!blogs) {
        return (
            <div className="flex relative justify-center items-center bg-black overflow-y-scroll h-screen">
                <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
                <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>

                <div className="w-3/4 relative p-10 my-10 rounded-lg backdrop-blur-sm border border-gray-600">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-x-4">
                        <Skeleton variant="circular" width={48} height={48} sx={{background:"gray"}} />
                        <div className="w-full">
                            <Skeleton width="100%" height={24} sx={{background:"gray"}}/>
                            <Skeleton width="40%" height={20} sx={{background:"gray"}}/>
                        </div>
                        </div>
                        {/* <Skeleton width={80} height={40} sx={{background:"gray"}}/> */}
                    </div>
                    <div className="mt-4">
                        <Skeleton variant="rectangular" height={200} sx={{background:"gray"}}/>
                    </div>
                    <div className="mt-4 items-center justify-center text-gray-300">
                        <Skeleton width="100%" height={100} sx={{background:"gray"}}/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex relative bg-black h-full">
            <div class="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div class="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
            <div className="md:w-3/4 w-10/12 relative md:p-10 p-5 md:my-10 rounded-lg backdrop-blur-sm border border-gray-600 mx-auto">
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-x-4">
                        <div className="w-12 h-12 rounded-full bg-gray-800 flex justify-center items-center overflow-hidden">
                            {blogs.user.user_profile ? (
                                <img className="w-12 h-12" src={`${blogs.user.user_profile}`} alt="User profile" />
                            ) : (
                                <UserIcon className="h-6 w-auto text-gray-200" />
                            )}
                        </div>
                        <div>
                            <h1 className="text-lg font-bold text-gray-200">{blogs.title}</h1>
                            <p className="text-xs text-gray-200">
                                Author: <span className="font-bold">{blogs.user.first_name} {blogs.user.last_name}</span>
                            </p>
                        </div>
                    </div>
                    <div>
                        {user.user_id === blogs.user.id ? 
                        <>
                        {is_edit? 
                            <div  className="flex items-center md:text-base text-xs group gap-x-2 bg-transparent text-gray-200 px-3 uppercase py-2 rounded-md border border-gray-600 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500"
                            onClick={(e) => set_isEdit(false)}
                            >
                                <PencilIcon className="w-6 text-gray-200 group-hover:text-indigo-600  " />
                                <span>Cancel Edit</span>
                            </div>
                        :
                            <div  className="flex items-center md:text-base text-xs group gap-x-2 bg-transparent text-gray-200 px-3 uppercase py-2 rounded-md border border-gray-600 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500"
                            onClick={(e) => set_isEdit(true)}
                            >   
                                <PencilIcon className="w-6 text-gray-200 group-hover:text-indigo-600  " />

                                <span>Edit Blog</span>
                            </div>
                        }
                        </>
                        :<></> }
                    </div>
                </div>
                {!is_edit ?
                <div className="relative">
                    <div className="mt-4 relative">
                        <img src={`${blogs.image}`} alt="blog" className="h-auto max-w-full object-fill rounded-lg" />
                    </div>
                    <div className="mt-4  text-gray-300 blog-description" dangerouslySetInnerHTML={{ __html: blogs.description }}>
                    </div>
                </div>
                :
                <ThemeProvider theme={darkTheme}>
                    {preview && (
                        <div className='flex items-center justify-center' >
                            <img src={preview} alt="Selected preview" className='w-auto h-60 object-fill' />
                        </div>
                    )}
                    <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<AddPhotoAlternateIcon />} style={{marginTop:'5px'}}>
                        Select Bolg Poster
                        <VisuallyHiddenInput type="file" accept="image/png, image/jpeg" onChange={handleFileChange}/>
                    </Button>

                    <Box component="div"  className="mt-5"  noValidate autoComplete="off">
                        <TextField type="text" placeholder="Course Name" label="Enter Blog Name" className="" value={title} onChange={(e) => setTitle(e.target.value)} fullWidth />
                    </Box>

                    <label htmlFor="email" className="block text-sm font-semibold mt-6 leading-6 text-gray-900 ">
                        Write Blog 
                    </label>
                    <div>
                        <ReactQuill className='rounded-lg h-full bg-white' formats={formats} modules={modules} theme="snow" value={value} onChange={setValue}>
                        </ReactQuill>
                    </div>
                    <div className="relative mt-8">
                        <button className="text-gray-200 uppercase border-gray-600 border px-3 py-2 rounded-md font-semibold hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-400 hover:to-pink-600"  onClick={handleEdit} >Edit Blog</button>
                    </div>
                </ThemeProvider>
                }
                
            </div>
        </div>
    );
}
