import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { UserIcon } from "@heroicons/react/20/solid";
import AuthContext from "../context/AuthContext";
import myConfig from "../configs/config";

import sahil from "../assests/experts/sahil.png"
import firstblog from "../assests/images/firstblog.png"

export function Blog1() {

    return (
        <div className="flex justify-center items-center bg-black text-gray-300 p-2 md:p-0">
            <div className="md:w-3/4 md:p-10 p-5 my-10 rounded-md border border-gray-600">
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-x-4">
                        <div className=" rounded-full flex justify-center items-center overflow-hidden">
                            <img className="md:w-12 md:h-12" src={sahil} alt="User profile" />
                        </div>
                        <div>
                            <h1 className=" text-3xl font-bold">The Blockchain Revolution: Unraveling the Future of Trust</h1>
                            <p className="text-sm">
                                Author: <span className="font-bold">Sahil Shaikh</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="m-4">
                    <img src={firstblog} alt="blog" className="w-full md:h-72 object-fit rounded-md" />
                </div>
                <div className="items-center justify-center">
                <p><span style={{ fontSize: '16px' }}><strong>The Genesis of Blockchain: A Brief History</strong></span></p>
                <p>
                    The story of blockchain begins in 2008 when an anonymous person or group of people using the pseudonym 
                    "Satoshi Nakamoto" published a whitepaper titled "Bitcoin: A Peer-to-Peer Electronic Cash System." 
                    This mysterious paper introduced Bitcoin, the world's first decentralized cryptocurrency, and the 
                    underlying technology that powers it - blockchain.
                </p>
                <br />
                <p><span style={{ fontSize: '16px' }}><strong>What is Blockchain?</strong></span></p>
                <p>
                    At its core, blockchain is a distributed and decentralized digital ledger that securely records 
                    transactions across multiple computers. Unlike traditional centralized systems where a single entity 
                    maintains control over the database, a blockchain network relies on a vast network of participants, 
                    often referred to as nodes, to validate and record transactions.
                </p>
                <p>
                    The name "blockchain" comes from the way data is stored in the system. Transactions are grouped together 
                    in blocks, and each block contains a cryptographic link to the previous block, forming a chain of blocks - 
                    hence the name "blockchain." This chain-like structure ensures that past transactions cannot be altered 
                    without altering all subsequent blocks, making the system resistant to tampering and fraud.
                </p>
                <br />
                <p><span style={{ fontSize: '16px' }}><strong>How Does Blockchain Work?</strong></span></p>
                <p>To understand how blockchain works, let's break down the process into simple steps:</p>
                <br />
                <ol>
                    <li>
                    <span style={{ fontSize: '14px' }}><strong>Transaction Initiation:</strong></span> When a user initiates a 
                    transaction, like transferring cryptocurrency or recording any digital information, it is broadcasted 
                    to the network.
                    </li>
                    <li>
                    <span style={{ fontSize: '14px' }}><strong>Verification:</strong></span> Nodes on the network compete to 
                    validate the transaction using complex mathematical algorithms. The first node to solve the puzzle adds 
                    the new block of transactions to the chain.
                    </li>
                    <li>
                    <span style={{ fontSize: '14px' }}><strong>Consensus:</strong></span> Consensus mechanisms, such as Proof-of-Work 
                    (PoW) or Proof-of-Stake (PoS), are employed to ensure that the majority of the network agrees on the 
                    validity of the new block.
                    </li>
                    <li>
                    <span style={{ fontSize: '14px' }}><strong>Block Addition:</strong></span> Once consensus is reached, the new 
                    block is added to the existing chain, and the transaction is considered confirmed.
                    </li>
                    <li>
                    <span style={{ fontSize: '14px' }}><strong>Immutability:</strong></span> As more blocks are added to the chain, 
                    the transaction becomes practically immutable, as altering a previous block would require changing 
                    subsequent blocks across the entire network, which is computationally infeasible.
                    </li>
                </ol>

                <br />
                <p><span style={{ fontSize: '16px' }}><strong>Blockchain Beyond Cryptocurrencies</strong></span></p>
                <p>
                    While cryptocurrencies were the first and most well-known application of blockchain technology, its potential 
                    extends far beyond digital money. Here are some areas where blockchain is making a significant impact:
                </p>
                <br />
                <ol>
                    <li>
                    <span style={{ fontSize: '14px' }}><strong>Supply Chain Management:</strong></span> Blockchain can provide 
                    transparency and traceability in supply chains, ensuring the authenticity and origin of products.
                    </li>
                    <li>
                    <span style={{ fontSize: '14px' }}><strong>Voting Systems:</strong></span> Blockchain-based voting systems can 
                    enhance the security and integrity of elections, making voting fraud much more difficult.
                    </li>
                    <li>
                    <span style={{ fontSize: '14px' }}><strong>Smart Contracts:</strong></span> Smart contracts are self-executing 
                    contracts with the terms of the agreement directly written into code. They automatically execute when 
                    conditions are met, removing the need for intermediaries.
                    </li>
                    <li>
                    <span style={{ fontSize: '14px' }}><strong>Healthcare:</strong></span> Blockchain can enable secure sharing of 
                    patient data across different healthcare providers while maintaining patient privacy.
                    </li>
                </ol>
                </div>
            </div>
        </div>
    );
}
