import React, { useContext, useEffect, useState } from "react";
import proA from "../assests/experts/proA.png"
import lastblog from "../assests/images/lastblog.png"

export function Blog3() {

    return (
        <div className="flex justify-center items-center bg-black text-gray-300 p-2 md:p-0">
            <div className="md:w-3/4 md:p-10 p-5 my-10 rounded-lg border border-gray-600 ">
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-x-4">
                        <div className="rounded-full bg-gray-800 flex justify-center items-center overflow-hidden">
                            <img className="md:w-12 md:h-12" src={proA} alt="User profile" />
                        </div>
                        <div>
                            <h1 className=" text-3xl font-bold">The Evolution of the Internet: Web 1, Web 2, and the Web3</h1>
                            <p className="text-sm">
                                Author: <span className="font-bold">Prof. Aishwarya</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="m-4">
                    <img src={lastblog} alt="blog" className="w-full md:h-72 object-fit rounded-md" />
                </div>
                <div className="items-center justify-center">
                    <br/>
                    <h3 className=" font-bold">Web 1: The Dawn of the Internet (1990s)</h3>
                    <p>
                        The early days of the internet, often referred to as Web 1, began in the 1990s. During this time, the internet 
                        primarily served as a platform for static web pages. Information flow was mostly one-way, with users passively 
                        consuming content. Key features of Web 1 included:
                    </p>
                    <ul>
                        <li><strong>Static Web Pages:</strong> Websites were simple and consisted of static HTML pages. There was minimal interactivity, and updates required manual coding.</li>
                        <li><strong>Limited User Participation:</strong> Interaction was limited, and user-generated content was rare. Websites were primarily created and managed by experts or webmasters.</li>
                        <li><strong>Dial-up Connections:</strong> Internet access was slow, primarily through dial-up connections, which meant that browsing was not as smooth as it is today.</li>
                        <li><strong>Information Retrieval:</strong> The primary purpose of the internet was to access information, and search engines like Yahoo! and AltaVista were used to find websites.</li>
                    </ul>

                    <br />
                    <h3 className=" font-bold">Web 2: The Interactive Web (2000s to Early 2010s)</h3>
                    <p>
                        The turn of the millennium marked the transition to Web 2, the era of interactive and dynamic websites. Web 2 brought 
                        significant advancements, creating a more user-focused and engaging internet experience. Key features of Web 2 included:
                    </p>
                    <ul>
                        <li><strong>Dynamic Content:</strong> Websites became more dynamic, with the introduction of technologies like JavaScript and AJAX, allowing real-time updates without page reloads.</li>
                        <li><strong>Social Media and User-Generated Content:</strong> Web 2 saw the rise of social media platforms like Facebook, Twitter, and YouTube. Users actively participated by creating and sharing content.</li>
                        <li><strong>Web Applications:</strong> The concept of web applications gained popularity. Users could now perform tasks online, such as email, document editing, and collaboration, all within a web browser.</li>
                        <li><strong>Mobile Revolution:</strong> The widespread adoption of smartphones and mobile internet access expanded the reach of the internet beyond desktop computers.</li>
                    </ul>
                    
                    <br />
                    <h3 className=" font-bold">Web3: The Decentralized Internet (Mid-2010s and Beyond)</h3>
                    <p>
                        The ongoing evolution of the internet has brought us to the exciting era of Web3, where decentralization and blockchain 
                        technology take center stage. Web3 aims to redefine how we interact with the digital world, promoting user empowerment 
                        and data ownership. Key features of Web3 include:
                    </p>
                    <ul>
                        <li><strong>Decentralization:</strong> Web3 relies on decentralized networks, such as blockchain, to eliminate the need for central authorities. This creates a trustless environment, reducing the risk of data manipulation and censorship.</li>
                        <li><strong>User Data Ownership:</strong> In Web3, users have greater control over their data. Instead of surrendering personal information to centralized platforms, users store data on decentralized networks, enhancing privacy and security.</li>
                        <li><strong>Cryptocurrencies and Digital Assets:</strong> Web3 embraces cryptocurrencies, enabling peer-to-peer transactions without intermediaries. It also introduces digital assets representing ownership or access rights to various services.</li>
                        <li><strong>Smart Contracts and DApps:</strong> Smart contracts, self-executing agreements, enable automated and transparent interactions. Decentralized applications (DApps) leverage these contracts to operate autonomously.</li>
                    </ul>

                    <br />
                    <h3 className=" font-bold">Key Differences: Web 1 vs. Web 2 vs. Web3</h3>
                    <ul>
                        <li><strong>Interactivity:</strong> Web 1 was static with limited interaction, Web 2 introduced user-generated content and social interactions, while Web3 enhances interactivity through decentralized applications.</li>
                        <li><strong>Data Control:</strong> Web 1 relied on centralized platforms owning user data, Web 2 gave users more control over their data, and Web3 emphasizes complete ownership and secure storage.</li>
                        <li><strong>Economic Paradigm:</strong> Web 1 had minimal economic activities, Web 2 introduced online commerce, and Web3 introduces cryptocurrencies and blockchain-based economies.</li>
                        <li><strong>Trust Model:</strong> Web 1 relied on trust in centralized entities, Web 2 shifted to trust in user reviews and social networks, and Web3 embraces trust through decentralized consensus mechanisms.</li>
                    </ul>

                    <br />
                    <h3 className=" font-bold">Conclusion</h3>
                    <p>
                        The evolution of the internet from Web 1 to Web 2 and now to the promising Web3 reflects the constant drive for 
                        innovation and user empowerment. Web3's focus on decentralization, data ownership, and blockchain technology has the 
                        potential to reshape the digital landscape, opening up new possibilities for a more transparent, secure, and inclusive 
                        internet. As Web3 continues to unfold, it's an exciting time to be a part of this transformational journey into the 
                        decentralized future.
                    </p>
                </div>
            </div>
        </div>
    );
}
