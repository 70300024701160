import { useState, useEffect, useRef } from "react";
import { ReactTyped } from "react-typed";
import { Link, useLocation } from 'react-router-dom';
import { Expert } from "../components/experts/experts";
import {
    BookOpenIcon,
    UserIcon,
    UserGroupIcon,
    RectangleStackIcon,
    AcademicCapIcon,
    CheckCircleIcon
    } from '@heroicons/react/20/solid'

import bannerApplyNow from '../assests/images/bannerApplyNow.png'
import {PopingCircles} from '../components/animation/popingEffect'

// blog Image
import firstblog from "../assests/images/firstblog.png"
import second from "../assests/images/second.png"
import lastblog from "../assests/images/lastblog.png"

// Course Image
import fullstack from "../assests/course_img/Full stack Blockchain .jpg"
import hardhat from "../assests/course_img/Hardhat testing.jpg"
import solidity_img from "../assests/course_img/Solidity.jpg"

import certificate from "../assests/icons/centificate.png"
import online_course from "../assests/icons/online course.png"
import image_icon from "../assests/icons/laptop.png"
// Making Rating Block
// import {Meteors}  from "../components/animation/meteor";
import worldmap from "../assests/course_img/worldmap.png"
import { createTheme, ThemeProvider } from '@mui/material';


// Comments

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination,Autoplay } from 'swiper/modules';
import { Card, CardContent, Avatar, Typography } from '@mui/material';
import { Star } from '@mui/icons-material';

// Comment Profiles
import neha from "../assests/reviews/image.png"
import priya from "../assests/reviews/image copy 2.png"
import aadi from "../assests/reviews/image copy.png"
import rajiv from "../assests/reviews/image copy 3.png"




const stats = [
  { id:1, name: 'Students Enrolled', value: '125', icon: AcademicCapIcon },
  { id:2, name: 'Class Completed', value: '50', icon: BookOpenIcon },
  { id:3, name: 'Skilled Instructors', value: '20', icon: UserGroupIcon },
  { id:4, name: 'Premium Courses', value: '3', icon: RectangleStackIcon },

]
// Custom hook for counting animation
function useCountAnimation(endValue, duration = 2000, startAnimation) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!startAnimation) return; // Only start if the component is in view

    let start = 0;
    const increment = endValue / (duration / 10); // Updates every 10ms

    const counter = setInterval(() => {
      start += increment;
      if (start >= endValue) {
        start = endValue;
        clearInterval(counter);
      }
      setCount(Math.ceil(start));
    }, 10);

    return () => clearInterval(counter);
  }, [endValue, duration, startAnimation]);

  return count;
}

// Subcomponent for each stat item with intersection observer
function StatItem({ stat }) {
  const [isInView, setIsInView] = useState(false);
  const statRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.unobserve(statRef.current); // Stop observing once in view
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    if (statRef.current) {
      observer.observe(statRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const animatedCount = useCountAnimation(stat.value, 2000, isInView);

  return (
    <div className="group mx-auto flex max-w-xs relative transform transition-all duration-300 hover:scale-110 border-gray-600 border rounded-lg">
      <div className="absolute hidden group-hover:block -inset-1 animate-gradient rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-75 group-hover:duration-100 bg-gradient-to-r from-blue-500 to-pink-500"></div>
      <div ref={statRef} className=" relative flex flex-col items-center gap-y-3 rounded-lg bg-gray-950 p-10 ">
        <h1 className="text-4xl font-bold tracking-tight text-gray-200   transition-transform duration-300 group-hover:scale-110 group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-500 group-hover:to-pink-500">
          <stat.icon className="h-20 w-auto inline text-gray-200 group-hover:text-pink-500" aria-hidden="true"  />
          {/* <stat.icon className="h-20 w-auto inline text-blue-500 group-hover:text-pink-500" aria-hidden="true"  /> */}
        </h1>
        <dd className="text-4xl font-bold tracking-tight text-gray-200  transition-transform duration-300 group-hover:scale-110 group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-500 group-hover:to-pink-500">
          {animatedCount}+
        </dd>
        <dt className="text-lg text-gray-400 transition-colors duration-200 group-hover:text-gray-300">{stat.name}</dt>
      </div>
    </div>
  );
}

// Background with animated gradient overlay and SVG pattern
function AnimatedBackground() {
  return (
    <div className="absolute inset-0 -z-10">
      <div className="w-full h-full bg-gradient-to-b from-blue-100 to-blue-50/5" />
      <svg className="absolute inset-0 w-full h-full opacity-65" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="url(#grad)" fillOpacity="1" d="M0,160L48,186.7C96,213,192,267,288,266.7C384,267,480,213,576,181.3C672,149,768,139,864,165.3C960,192,1056,256,1152,261.3C1248,267,1344,213,1392,186.7L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
      </svg>
    </div>
  );
}

function CourseStats() {
  return (
    <div className="relative md:py-32 py-0 ">
      {/* <AnimatedBackground /> */}
      <div className="mx-auto relative max-w-7xl px-4 lg:px-8">
        <h2 className="text-center text-2xl font-bold text-gray-200 sm:text-4xl mb-16 uppercase transition-transform duration-500 hover:scale-125">Our Course Statistics</h2>
        <dl className="relative grid grid-cols-1 gap-8 md:grid-cols-4 text-center ">
          {stats.map((stat) => (
            <StatItem key={stat.id} stat={stat} />
          ))}
        </dl>
      </div>
    </div>
  );
}

function ApplyNow() {
  // State for fade-in animation
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay for animation
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`my-28  gap md:max-w-full transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
      <div className="md:flex px-10">
        <div className="md:shrink-0">
          <img className="object-fill md:px-10 md:h-80 w-full saturate-150 contrast-125" src={bannerApplyNow} alt="image" />
        </div>

        <div className="relative md:flex-1 group md:my-0 my-8  ">
          <div className="absolute -inset-1 animate-gradient rounded-md blur opacity-75 group-hover:opacity-100 transition duration-75 group-hover:duration-100 bg-gradient-to-r from-blue-500 to-pink-500"></div>
          <div className="relative bg-gradient-to-br from-black to-gray-800 p-8 rounded-md overflow-hidden">
            <Meteors number={15} />
            <h5 className="text-3xl font-bold  text-gray-200 transition-colors duration-700 ease-in-out group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-500 group-hover:to-pink-500 ">
              Increase Your Higher Education Level with Blockchain Pathshala
            </h5>
            <p className="py-10 group-hover:text-gray-300 transition-colors duration-700 text-md font-semibold text-gray-400">
              Helping employees gain skills and providing career development often take a back seat to business priorities but workplace. We offer fresh courses on emerging topics that keep your level.
            </p>

            {/* Course Features with Hover Effects */}
            <div className="relative group-hover:text-white transition-colors duration-700 ease-in-out space-y-1 text-md text-gray-400">
              <div className="flex items-center">
                <CheckCircleIcon className="text-sm h-4 w-auto text-gray-300 group-hover:text-white transition-colors duration-700 mr-2" />
                <span >Course curriculum</span>
              </div>
              <div className="flex items-center">
                <CheckCircleIcon className="text-sm h-4 w-auto text-gray-300 group-hover:text-white transition-colors duration-700 mr-2" />
                <span>Easy to enroll courses</span>
              </div>
              <div className="flex items-center">
                <CheckCircleIcon className="text-sm h-4 w-auto text-gray-300 group-hover:text-white transition-colors duration-700 mr-2" />
                <span>Know the latest technology</span>
              </div>
            </div>

            <div className="mt-16 relative items-center justify-center w-fit flex md:justify-start text-gray-400">
              {/* <span className="absolute  h-5 w-5 rounded-full bg-gradient-to-r from-pink-600 to-blue-600 transition-all  duration-700 group-hover:scale-[93]"></span> */}
              <Link to="/login" className="relative group rounded-sm  text-sm border hover:border-gray-200 border-gray-600 font-semibold transition-all duration-500 hover:scale-110 ">
                  <div className="relative bg-gray-900">
                    <button className="realtive p-2 animate-gradient bg-gray-950/55 uppercase hover:bg-clip-text hover:text-transparent group-hover:duration-100 hover:bg-gradient-to-r hover:from-blue-500/85 hover:to-pink-500/85 ">
                      Start Learning
                    </button>   
                  </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const totalCourse = [
  { id:1, name: 'Full Stack Blockchain Development', discount:"5", description: 'Master the skills needed to build comprehensive decentralized applications from scratch.', image: fullstack, url: '/full-stack' },
  { id:2, name: 'Ethereum Smart Contract Testing Hardhat', discount:"5", description: 'Gain expertise in testing and securing Ethereum smart contracts using Hardhat.', image: hardhat , url: '/hardhat'},
  { id:3, name: 'Introduction to Solidity Programming', discount:"5", description: 'Explore the fundamentals of Solidity programming to create and deploy smart contracts.', image:  solidity_img, url: '/solidity' },
]

export function PickCourse() {
  return (
    <>
      <div className="md:max-w-full mx-10 mb-10">
        {/* Heading */}
        <h2 className="text-3xl font-bold my-10 text-gray-200 text-center transition duration-300  ease-in-out hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
        Pick Your<br />
        Professional Course
        </h2>

        <div className="grid md:grid-cols-3 md:gap-10 gap-y-10 lg:mx-20 relative">
          {totalCourse.map((course) => (
            <div key={course.id} className=" relative group border rounded-sm border-gray-700 ">
              <div className="absolute -inset-3  animate-gradient rounded-sm blur opacity-55 group-hover:opacity-100 bg-gradient-to-r from-blue-500 to-pink-500 transition duration-500 group-hover:duration-100 hidden group-hover:block	"></div>
              <div className="bg-transparent relative rounded-sm shadow-lg h-full transition-transform duration-300 ease-in-out group-hover:scale-105 overflow-hidden ">
                <img
                  className="h-auto max-w-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-100"
                  src={course.image}
                  alt="course"
                />
                  <div className="top-8 -right-9 rotate-45 absolute flex justify-center">
                    <div className="relative">
                    <div className="absolute -inset-1 animate-gradient rounded-full blur opacity-75 group-hover:opacity-100 transition duration-75 group-hover:duration-100 bg-gradient-to-r from-blue-500 to-pink-500"></div>
                      <div className="relative px-9 py-1 text-xs leading-6 text-gray-600 bg-white/85">
                          <span className="absolute inset-0" aria-hidden="true" />
                          5% off Join Now
                      </div>
                    </div>
                  </div>
                <div className="p-6 bg-black">
                  <h5 className="text-lg h-12 font-semibold text-gray-200 transition-colors duration-200 group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-500 group-hover:to-pink-500">
                    {course.name}
                  </h5>
                  <p className="pt-3 text-sm h-20 text-gray-400 transition-opacity duration-200 group-hover:opacity-75">
                    {course.description}
                  </p>
                  <div className="relative bottom-0 w-full grid grid-cols-2 gap-4 ">
                    <Link to="/login" className="w-full relative group rounded-sm  text-sm border  border-gray-50/35 font-semibold text-white">
                        {/* <div className="absolute hidden group-hover:block -inset-1 animate-gradient rounded-sm blur opacity-75 group-hover:opacity-100 transition duration-75 group-hover:duration-100 bg-gradient-to-r from-blue-500 to-pink-500"></div> */}
                        <div className="relative bg-gray-900">
                          <button className="realtive md:text-sm text-xs p-2 w-full bg-gray-950/55 uppercase hover:bg-clip-text hover:text-transparent group-hover:duration-100 hover:bg-gradient-to-r hover:from-blue-500/85 hover:to-pink-500/85 ">
                            Start Learning
                          </button>   
                        </div>
                    </Link>
                    <Link to={course.url} className="flex-1 md:text-sm text-xs flex text-center font-semibold justify-center p-2 w-full text-gray-200 rounded-sm uppercase transition  duration-500 ease-in-out  hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                          KNOW MORE <span aria-hidden="true">→</span>
                      </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Uncomment if needed */}
        {/* <div className="flex items-center justify-center gap-x-6 my-10">
          <Link to="/courses" className="rounded-md bg-blue-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
            View All Courses
          </Link>
        </div> */}
      </div>
    </>
  );
}


const FeaturesArea = () => {
  return (
      <div className="features-area mt-10 md:py-12 py-4 md:px-auto px-2 bg-gray-900" data-aos="fade-zoom-in" data-aos-duration="2000" style={{ backgroundImage: 'url("assets/img/fact/fact.png")' }}>
          <div className="container mx-auto text-gray-300 transition-transform hover:scale-105 duration-500 ease-in-out hover:bg-clip-text hover:text-transparent  hover:bg-gradient-to-r from-blue-500 to-pink-500">
              <div className="flex ">
                  {/* Feature 1 */}
                  <div className="w-full sm:w-1/2 lg:w-1/3 h-10">
                      <div className="features-wrapper flex items-center ">
                          <div className="features-icon mr-4 text-md">
                              <img className="md:h-16 h-10 invert opacity-80 "  src={online_course}></img>
                          </div>
                          <div className="features-content">
                              <h3 className="md:text-xl text-xs font-bold">Learn with Industrial <br />Experts</h3>
                          </div>
                      </div>
                  </div>
                  
                  {/* Feature 2 */}
                  <div className="w-full sm:w-1/2 lg:w-1/3 ">
                      <div className="features-wrapper flex items-center ">
                          <div className="features-icon mr-4 text-md">
                              <img className="md:h-16 h-10 invert opacity-80"  src={certificate}></img>
                          </div>
                          <div className="features-content">
                              <h3 className="md:text-xl text-xs font-bold">Learn with Industrial <br />Experts</h3>
                          </div>
                      </div>
                  </div>

                  {/* Feature 3 */}
                  <div className="w-full sm:w-1/2 lg:w-1/3">
                      <div className="features-wrapper flex items-center ">
                          <div className="features-icon mr-4 text-md">
                              <img className="md:h-16 h-10 invert opacity-80"  src={image_icon}></img>
                          </div>
                          <div className="features-content">
                              <h3 className="md:text-xl text-xs font-bold">Learn from Anywhere,<br /> Any time</h3>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

const Partners = () =>{
  const images = [];
  for (let i = 0; i <= 11; i++) {
    images.push(require(`../assests/partners/image${i === 11 ? '' : ' copy' + (i ===11 ? '' : ' ' + (i + 1))}.png`));
  }

  return(
    <div className="flex md:p-16 p-5 w-full justify-center" >
      <div className={`md:w-4/12 w-6/12` }>
        <div className="relative space-y-5 backdrop-transparent">
          <img className="invert absolute opacity-50 top-0 saturate-150 contrast-200 " src={worldmap} />
          <div className="relative md:p-8 p-2">
            <div className="md:text-2xl text-md text-gray-300 font-bold transition-transform duration-300 hover:scale-110 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">The world's most innovative companies that recruit for blockchain</div>
            <div className="md:text-md text-xs text-gray-400">Global partners has been Publish the course you want, in the way you want always have of control.</div>
          </div>
        </div>
      </div>
      <div className="relative md:w-8/12 w-6/12 md:p-8 p-3 border hover:animate-gradient rounded-lg border-gray-600 grid md:grid-cols-4 grid-cols-3 bg-gradient-to-br from-gray-300 to to-slate-800 md:gap-16 gap-1">
        {images.map((src, index) => (
          <img className=" saturate-150 transition-transform duration-300 hover:scale-110" key={index} src={src} alt={`Image ${index}`} />
        ))}
      </div>
    </div>
  )
}


const comments = [
  {
    name: "Rajiv Patel",
    role: "Student",
    image: rajiv,
    title: "Best Experience!",
    feedback: "The blockchain training was nothing short of exceptional! The instructors were experts in the field. I appreciated the interactive sessions and group projects that encouraged collaboration. Now, I feel ready to contribute to the blockchain revolution!",
    stars: 4,
  },
  {
    name: "Neha Desai",
    role: "Student",
    image: neha,
    title: "Best Experience!",
    feedback: "The blockchain program exceeded my expectations! The curriculum was well-structured, covering both theoretical concepts and practical aspects. The mentors were incredibly supportive, guiding me throughout the learning journey.",
    stars: 5,
  },
  {
    name: "Aditya Shinde",
    role: "Student",
    image: aadi,
    title: "Helpful Instructors!",
    feedback: "Enrolling in the blockchain course was a game-changer for me. The instructors were top-notch, and the hands-on projects allowed me to apply what I learned in real-world scenarios. I'm now confidently building blockchain applications. Highly recommended!",
    stars: 4,
  },
  {
    name: "Priya Sharma",
    role: "Student",
    image: priya,
    title: "Great Platform!",
    feedback: "I'm amazed at the blockchain course's depth and quality. The hands-on labs and real-world case studies provided invaluable insights. This program has opened up exciting career opportunities for me in the blockchain domain.",
    stars: 5,
  },
];

const Commentssection = () => {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  return (
    <div className="testimonial-area pb-12" data-aos="zoom-in-up" data-aos-duration="1000">
      <div className="container mx-auto">
        <div className="text-center items-center justify-center flex mb-14">
          <h3 className="md:text-3xl text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-pink-500 w-fit">
            What Students Think and Say About <br /> <span className="font-Semibold">Blockchain Pathshala</span>
          </h3>
        </div>
        <ThemeProvider theme={darkTheme}>
          <Swiper
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true ,el: '.swiper-pagination' }}
            autoplay={{ delay: 3000 }} 
            spaceBetween={30}
            slidesPerView={2}
            loop={true}
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
          >
            {comments.map((comment, index) => (
              <SwiperSlide key={index}>
                <Card className="p-6 shadow-lg h-full">
                  <div className="flex items-center mb-4">
                    <Avatar src={comment.image} alt={`${comment.name} photo`} sx={{ width: 56, height: 56 }} />
                    <div className="ml-4">
                      <Typography variant="h6" component="div">{comment.name}</Typography>
                      <Typography variant="subtitle2" color="text.secondary">{comment.role}</Typography>
                    </div>
                  </div>
                  <CardContent>
                    <Typography variant="h6" className="mb-2">{comment.title}</Typography>
                    <Typography variant="body2" color="text.secondary" className="mb-4 md:h-56 h-96">
                      "{comment.feedback}"
                    </Typography>
                    <div className="flex absolute">
                      {[...Array(comment.stars)].map((_, i) => (
                        <Star key={i} className="text-yellow-500" />
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </SwiperSlide>
            ))}
          <div className="testimonial-pagination swiper-pagination mt-4 text-center" />
          </Swiper>
        </ThemeProvider>
      </div>
    </div>
  );
};
  

export function Home() {
    return (
      <div className="relative bg-black">
        <div className="relative inset-0 overflow-hidden h-full w-full ">
          {/* Animated SVG Background */}
          <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
          <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>

          {/* Content Area */}
          <div className=" mx-auto max-w-2xl md:py-40 py-20 lg:py-25">

            <div className="group mb-8 flex justify-center scale-75 md:scale-100">
              <div className="relative">
              <div className="absolute -inset-1 animate-gradient rounded-full blur opacity-75 group-hover:opacity-100 transition duration-75 group-hover:duration-100 bg-gradient-to-r from-blue-500 to-pink-500"></div>
                <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 bg-white/85">
                  Enroll now and get 5% off course..{' '}
                  <Link to="/courses" className="font-semibold text-blue-800 hover:text-purple-900 transition duration-200">
                      <span className="absolute inset-0" aria-hidden="true" />
                      Select Course<span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
              </div>
            </div>


            <div className="relative text-start md:p-x-0 px-8">
                <div className="">
                  {/* <div class="absolute -z-10 inset-3 bg-gradient-to-r from-red-600 to-violet-600 rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200">
                  </div> */}
                  <h1 className="text-3xl w-fit font-bold tracking-tight text-gray-200 md:text-6xl transition-transform duration-300 hover:scale-110 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                      Become a Next-Gen Developer
                  </h1>
                </div>
                <p className="animate-typing my-6 md:text-md text-sm leading-8 text-white font-mono h-28">
                    <ReactTyped
                        strings={[
                            "Unlock the potential of blockchain technology with our comprehensive training course. Master the fundamentals and dive deep into real-world applications for a future-ready skillset.",
                            "Explore blockchain's impact on business and technology with our comprehensive courses in security, development, and industry applications.",
                        ]}
                        typeSpeed={50}
                        loop
                    />
                </p>
                <div className="md:mt-10 mt-20 flex items-center justify-center gap-x-6">
                    <Link to="/login" className="relative group rounded-sm  text-sm border hover:border-transparent border-gray-50/35 font-semibold text-white transition-all duration-500 hover:scale-110 ">
                        <div className="absolute hidden group-hover:block -inset-1 animate-gradient rounded-sm blur opacity-75 group-hover:opacity-100 transition duration-75 group-hover:duration-100 bg-gradient-to-r from-blue-500 to-pink-500"></div>
                        <div className="relative bg-gray-900">
                          <button className="realtive p-2 animate-gradient bg-gray-950/55 uppercase hover:bg-clip-text hover:text-transparent group-hover:duration-100 hover:bg-gradient-to-r hover:from-blue-500/85 hover:to-pink-500/85 ">
                            Start Learning
                          </button>   
                        </div>
                    </Link>
                    <Link to="/about-us" className="text-sm font-semibold text-gray-200 uppercase transition  duration-500 ease-in-out  hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                        Learn more <span aria-hidden="true">→</span>
                    </Link>
                </div>
            </div>
          </div>
        </div>

        <CourseStats />
        <ApplyNow />
        <PickCourse />
        <Expert />
        <FeaturesArea />
        <Partners />
        <Commentssection />
          
      </div>          
    );
    }

    function Meteors ({ number }) {
      return [...new Array(number || 20).fill(true)].map((el, idx) => (
        <span
          key={idx}
          class="meteor animate-meteor-effect absolute  h-0.5 w-0.5 rounded-[9999px] bg-slate-500 shadow-[0_0_0_1px_#ffffff10] rotate-[215deg]"
          style={{
            // top: Math.floor(Math.random() * (0 - -400) + -400) + 'px',
            top: 0,
            left: Math.floor(Math.random() * (400 - -400) + -400) + 'px',
            animationDelay: Math.random() * (0.8 - 0.2) + 0.2 + 's',
            animationDuration: Math.floor(Math.random() * (10 - 2) + 2) + 's',
          }}
        ></span>
      ))
    }