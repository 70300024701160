import React from 'react';
import { UserIcon } from "@heroicons/react/20/solid";
import 'react-phone-input-2/lib/style.css';
import fullstack from "../../assests/course_img/Full stack Blockchain .jpg";
import { Link } from 'react-router-dom';

export function FullStack() {
    return (
      <div className='bg-black'>
        <div className="bg-black bg-opacity-90 rounded-3xl flex flex-col md:flex-row md:w-11/12 w-11/12 mx-auto py-8 shadow-lg">
            <div className="md:w-6/12 w-11/12">
                <div className="relative p-4">
                    <img src={fullstack} className="h-auto w-full object-fit rounded-3xl" alt="User Profile" />
                    <div className=" bottom-4 left-4  bg-opacity-75 p-2 rounded-lg">
                      <h3 className="text-lg font-bold text-gray-200 ">Full Stack Blockchain Course</h3>
                      <h3 className="text-sm font-bold text-gray-300 ">By Sahil Shaikh</h3>
                      <p className="text-sm text-gray-200 mb-4">Price: ₹4999</p>
                      <Link 
                        className="mt-4 relative border border-gray-400 text-white px-4 py-2 rounded-sm"
                        to={'/login'}
                      >
                        Enroll Now
                      </Link>
                    </div>
                </div>
            </div>
            <div className="text-white p-4 md:w-6/12 w-11/12">
                <h2 className="text-2xl font-bold mb-4">Course Modules</h2>
                <div className="space-y-4">
                    <Module title="Module 1: Introduction to Web Development" topics={[
                        "Introduction to HTML, CSS, and JavaScript",
                        "Building and Styling Web Pages",
                        "Adding Interactivity with JavaScript"
                    ]} />
                    <Module title="Module 2: Frontend Development with React" topics={[
                        "Introduction to React and Component-Based UI",
                        "State Management and Props",
                        "Building Responsive and Dynamic UIs"
                    ]} />
                    <Module title="Module 3: Backend Development with Node.js and Express" topics={[
                        "Introduction to Node.js and Backend Development",
                        "Creating RESTful APIs with Express",
                        "User Authentication and Authorization"
                    ]} />
                    <Module title="Module 4: Introduction to Blockchain and Ethereum" topics={[
                        "What is Blockchain Technology?",
                        "Introduction to Ethereum and Smart Contracts",
                        "Ethereum Virtual Machine (EVM) and Gas"
                    ]} />
                    <Module title="Module 5: Solidity Smart Contract Development" topics={[
                        "Solidity Overview and Syntax",
                        "Data Types, Variables, and Functions",
                        "Events and Logging",
                        "Inheritance and Polymorphism",
                        "Abstract Contracts and Interfaces",
                        "Libraries and Reusable Code",
                        "Storage vs Memory vs Calldata",
                        "Common Security Vulnerabilities (e.g., reentrancy, overflow)",
                        "Best Practices for Secure Smart Contract Development",
                        "Using OpenZeppelin for Security"
                    ]} />
                    <Module title="Module 6: Frontend Blockchain Integration" topics={[
                        "Introduction to Web3.js library",
                        "Connecting Web3.js to Ethereum Networks",
                        "Reading and Writing to Smart Contracts from the Frontend"
                    ]} />
                    <Module title="Module 7: Backend Blockchain Integration" topics={[
                        "Building Backend Services to Interact with Smart Contracts",
                        "Integrating Web3.js and Ether.js with Backend"
                    ]} />
                    <Module title="Module 8: Full Stack DApp Development" topics={[
                        "Building a Decentralized Application (DApp) from Scratch",
                        "Integrating Frontend and Backend Services",
                        "User Interfaces for Smart Contract Interactions"
                    ]} />
                    <Module title="Module 9: Advanced Smart Contract Concepts" topics={[
                        "Chainlink Oracles for External Data Integration",
                        "Integrating Graph Protocol for Data Querying",
                        "Developing DeFi Smart Contracts: Lending, Yield Farming, etc."
                    ]} />
                    <Module title="Module 10: NFT and DeFi Applications" topics={[
                        "Understanding Non-Fungible Tokens (NFTs)",
                        "Developing NFT Marketplaces and Minting Platforms",
                        "Exploring Decentralized Finance (DeFi) Applications"
                    ]} />
                    <Module title="Module 11: Security and Best Practices" topics={[
                        "Common Security Vulnerabilities in Smart Contracts",
                        "Implementing Security Measures in Backend and Frontend",
                        "User Data Protection and Encryption"
                    ]} />
                    <Module title="Module 12: Final Projects and Real-World Use Cases" topics={[
                        "Exploring Different Use Cases for Blockchain Applications",
                        "Choosing Appropriate Blockchain Networks (Mainnet, Testnets)",
                        "Developing a Full Stack Blockchain Application",
                        "Final Project Presentation and Code Review"
                    ]} />
                </div>
                <div className="mt-8">
                    <h3 className="text-xl font-bold text-gray-200">Assessment and Evaluation:</h3>
                    <ul className="list-disc list-inside text-gray-300 text-sm">
                        <li>Weekly Coding Assignments: 40%</li>
                        <li>Midterm Projects (Frontend and Backend Components): 20%</li>
                        <li>Full Stack DApp Project: 30%</li>
                        <li>Participation and Engagement: 10%</li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
    );
}

function Module({ title, topics }) {
    return (
        <div>
            <h3 className="text-xl font-semibold text-gray-200">{title}</h3>
            <ul className="list-disc list-inside ml-4 text-sm text-gray-300">
                {topics.map((topic, index) => (
                    <li key={index}>{topic}</li>
                ))}
            </ul>
        </div>
    );
}