import React, { useContext, useState } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import myConfig from '../configs/config';
import { createTheme, Skeleton, ThemeProvider } from '@mui/material';

const ContactPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();


    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
      });
    // Submit contact form
    const handleSubmit = () => {
        const contactUrl = `${myConfig.CRU_URL}/courses/contact/`;
        
        if (name === '' || email === '' || message === '') {
            alert('Please fill all fields');
            return;
        }

        const contactData = {
            name: name,
            email: email,
            message: message
        };

        axios({
            baseURL: contactUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: contactData
        })
        .then((res) => {
            console.log(res);
            if (res.status === 200) {
                console.log("Message sent successfully");
                alert("Thank you for contacting us!");
                navigate('/');
            }
        })
        .catch((error) => {
            console.error("There was an error sending the message:", error);

        });
    };

    return (
        <div className="relative h-full bg-black ">
            {/* <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                aria-hidden="true"
                >
                <defs>
                    <pattern
                    id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                    width={200}
                    height={200}
                    x="10%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                    >
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                </defs>
                <svg x="10%" y={-1} className="overflow-visible fill-blue-50 ">
                    <path
                    d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                    strokeWidth={0}
                    />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div> */}
            <div class="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div class="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
            
            <div className=" relative mx-auto max-w-2xl py-20 ">
                <div className="text-center">
                    <h4 className="font-bold  uppercase transition-transform duration-300 hover:scale-110 tracking-wide text-gray-200 md:text-5xl text-3xl hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                    Contact BlockChain  <br/>Pathshala
                    </h4>
                    {/* <p className="animate-typing mt-6 text-lg leading-8 text-gray-600 font-mono px-4">
                        <ReactTyped strings={
                        [
                            "In the fast-paced world of technology, there's a term that has been making waves..",
                            "In recent years, you might have heard the term 'Web3' buzzing around in tech circles...",
                            "The internet has come a long way since its inception. Over the years, it has gone..."
                        ]
                        } typeSpeed={50} loop  />
                        
                    </p> */}
                    
                </div>
            </div>
            <div className="md:mx-10 mx-4 relative md:p-10 p-5 rounded-lg border border-gray-600 ">
                <ThemeProvider theme={darkTheme}>
                <div className='md:flex gap-5'>
                    <Box component="div" className="mt-5 flex-1" noValidate autoComplete="off">
                        <TextField
                            
                            type="text"
                            placeholder="Your Name"
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            required
                        />
                    </Box>

                    <Box component="div" className="mt-5 flex-1" noValidate autoComplete="off">
                        <TextField
                            type="email"
                            placeholder="Your Email"
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            required
                        />
                    </Box>
                </div>

                <Box component="div" className="mt-5" noValidate autoComplete="off">
                    <TextField
                        type="text"
                        placeholder="Your Message"
                        className=''
                        label="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        multiline
                        rows={4}
                        fullWidth
                        required
                    />
                </Box>
                </ThemeProvider>

                <div className="relative mt-8 flex">
                    <button className='border border-gray-600 px-3 bg-black py-2.5 text-gray-200 hover:scale-110 transition-transform duration-150' variant="outlined" onClick={handleSubmit}>Send Message</button>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
