import { Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import myConfig from "../../configs/config"

export function ActivateAccount() {
    const [isLoading, setIsLoading] = useState(true);
    const [isActivated, setIsActivated] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    // Getting UUID and Token from Links

    const { uuid, token } = useParams();
    
    useEffect(() => {
        const activateAccount = async () => {
            const activateUrl = `${myConfig.CRU_URL}/api/activate/${uuid}/${token}/`;
            setIsLoading(true);
            try {
                const response = await fetch(activateUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                const data = await response.json();
                console.log(data);
                if (response.status == 200) {
                    
                    setIsActivated(true);
                    setMessage(data.message);
                } else {
                    setIsActivated(false);
                    setMessage(data.message);
                }
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        }
        activateAccount();
    }
    , [uuid, token]);
    


    return (
      <div className=" relative bg-black">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
        <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
        <main className="grid relative min-h-full place-items-center bg-transparent px-6 py-64 sm:py-56 lg:px-8">
          <div className="text-center ">
            {isActivated ? 
            <div>
                <div className="flex items-center justify-center">
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-transparent w-fit bg-clip-text bg-gradient-to-r from-blue-500 to-pink-500">Activation <br/>Successful </h1>
                </div>
                <p className="mt-6 text-base leading-7 text-gray-400">{message}</p>
            </div>
            :
                <div>
                    {isLoading ? 
                    <div>
                        <div className="flex items-center justify-center">
                            <h1 className="mt-4 text-3xl font-bold tracking-tight text-transparent w-fit bg-clip-text bg-gradient-to-r from-blue-500 to-pink-500">Activating...... </h1>
                        </div>
                        <p className="mt-6 text-base leading-7 text-gray-400">Your Account Kindly Wait!</p>
                    </div>:
                    <div>
                        <div className="flex items-center justify-center">
                            <h1 className="mt-4 text-3xl font-bold tracking-tight text-transparent w-fit bg-clip-text bg-gradient-to-r from-blue-500 to-pink-500">Activation <br/>Failed </h1>
                        </div>
                        <p className="mt-6 text-base leading-7 text-gray-400">{message}</p>
                    </div>
                    }
                </div>
            
             }
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to={"/login"}
                className="rounded-sm px-3.5 py-2.5 text-sm font-semibold text-gray-200 border border-gray-500 transition duration-200 hover:scale-110 "
              >
                Go to Login
              </Link>
              <Link to={"/contact-us"} className="text-sm font-semibold text-gray-200 hover:scale-110 border-b border-transparent hover:border-gray-500 transition duration-150">
                Contact support <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
        </main>
        <div className="absolute inset-x-0 top-[calc(50%-9rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(50%-30rem)]" aria-hidden="true" >
            <div className=" animate-bounce relative left-[calc(10%+3rem)] aspect-[1155/678] w-[50.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#104afa] to-[#be04fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                style={{clipPath:'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }} />
        </div>
      </div>
    )
  }
  