import React from 'react';
import { UserIcon } from "@heroicons/react/20/solid";
import 'react-phone-input-2/lib/style.css';
import hardhat from "../../assests/course_img/Hardhat testing.jpg";
import { Link } from 'react-router-dom';

export function HardHat() {
    return (
        <div className='bg-black'>
            <div className="bg-black bg-opacity-90 rounded-3xl flex flex-col md:flex-row md:w-11/12 w-11/12 mx-auto py-8 shadow-lg">
                <div className="md:w-6/12 w-11/12">
                    <div className="relative p-4">
                        <img src={hardhat} className="h-auto w-full object-fit rounded-3xl" alt="Hardhat Course" />
                        <div className="bottom-4 left-4 bg-opacity-75 p-2 rounded-lg">
                            <h3 className="text-lg font-bold text-gray-200">Comprehensive Smart Contract Testing with Hardhat</h3>
                            <h3 className="text-sm font-bold text-gray-300">By Rohan Ramteke</h3>
                            {/* <p className="text-sm text-gray-200 mb-4">Course Duration: 8 weeks (16 sessions)</p> */}
                            <p className="text-sm text-gray-200 mb-4">Price: ₹4999</p>
                            <Link 
                                className="mt-4 relative border border-gray-400 text-white px-4 py-2 rounded-sm"
                                to={'/login'}
                            >
                                Enroll Now
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="text-white p-4 md:w-6/12 w-11/12">
                    <h2 className="text-2xl font-bold mb-4">Course Modules</h2>
                    <div className="space-y-4">
                        <Module title="Week 1-2: Introduction to Smart Contract Testing and Hardhat" topics={[
                            "Importance of Testing in Blockchain Development",
                            "Types of Tests: Unit, Integration, Functional",
                            "Overview of Testing Frameworks: Mocha, Chai, Hardhat",
                            "Setting up a Hardhat Project and Running Basic Tests"
                        ]} />
                        <Module title="Week 3-4: Unit Testing Smart Contracts" topics={[
                            "Writing Unit Tests for Individual Smart Contract Functions",
                            "Using Mocks, Stubs, and Test Helpers",
                            "Testing State Changes and Events",
                            "Test-Driven Development (TDD) Approach"
                        ]} />
                        <Module title="Week 5-6: Integration Testing and Contract Interactions" topics={[
                            "Writing Integration Tests for Complex Scenarios",
                            "Simulating Multi-Contract Interactions",
                            "Isolating Contracts Using Mocks and Stubs"
                        ]} />
                        <Module title="Week 7: End-to-End (E2E) Testing" topics={[
                            "Understanding E2E Testing in Decentralized Applications",
                            "Setting Up E2E Tests with Hardhat Network Forking",
                            "Testing User Flows and Transactions"
                        ]} />
                        <Module title="Week 8: Contract Security and Advanced Testing Techniques" topics={[
                            "Security-Focused Testing: Identifying Common Vulnerabilities",
                            "Property-Based Testing with Solidity",
                            "Using Hardhat plugins for Code Coverage and Gas Estimation"
                        ]} />
                        <Module title="Week 9-10: Test Automation and Continuous Integration" topics={[
                            "Automating Test Execution with Hardhat Tasks",
                            "Integrating Tests into Continuous Integration Pipelines",
                            "Ensuring Consistent Test Environments"
                        ]} />
                        <Module title="Week 11: Upgrades and Compatibility Testing" topics={[
                            "Smart Contract Upgrades: Importance and Challenges",
                            "Writing Tests for Contract Upgrades",
                            "Ensuring Compatibility and Data Migration"
                        ]} />
                        <Module title="Week 12: Real-World Project and Final Presentations" topics={[
                            "Building a Decentralized Application (DApp) with Robust Testing",
                            "Integrating Frontend and Backend Testing",
                            "Final Project Presentations and Code Review"
                        ]} />
                    </div>
                    <div className="mt-8">
                        <h3 className="text-xl font-bold text-gray-200">Assessment and Evaluation:</h3>
                        <ul className="list-disc list-inside text-gray-300 text-sm">
                            <li>Weekly Testing Assignments: 40%</li>
                            <li>Midterm Project (Unit and Integration Testing): 15%</li>
                            <li>E2E Testing and Security Assessment: 15%</li>
                            <li>Test Automation and Continuous Integration: 10%</li>
                            <li>Final Project and Presentation: 20%</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Module({ title, topics }) {
    return (
        <div>
            <h3 className="text-xl font-semibold text-gray-200">{title}</h3>
            <ul className="list-disc list-inside ml-4 text-sm text-gray-300">
                {topics.map((topic, index) => (
                    <li key={index}>{topic}</li>
                ))}
            </ul>
        </div>
    );
}