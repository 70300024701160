import React from 'react';
import { UserIcon } from "@heroicons/react/20/solid";
import 'react-phone-input-2/lib/style.css';
import solidity from "../../assests/course_img/Solidity.jpg";
import { Link } from 'react-router-dom';

export function Solidity() {
    return (
        <div className='bg-black'>
            <div className="bg-black bg-opacity-90 rounded-3xl flex flex-col md:flex-row md:w-11/12 w-11/12 mx-auto py-8 shadow-lg">
                <div className="md:w-6/12 w-11/12">
                    <div className="relative p-4">
                        <img src={solidity} className="h-auto w-full object-fit rounded-3xl" alt="Solidity Course" />
                        <div className="bottom-4 left-4 bg-opacity-75 p-2 rounded-lg">
                            <h3 className="text-lg font-bold text-gray-200">Introduction to Solidity Programming</h3>
                            <h3 className="text-sm font-bold text-gray-300">By Sahil Shaikh</h3>
                            <p className="text-sm text-gray-200 mb-4">Course Duration: 8 weeks (16 sessions)</p>
                            <p className="text-sm text-gray-200 mb-4">Price: ₹4999</p>
                            <Link 
                                className="mt-4 relative border border-gray-400 text-white px-4 py-2 rounded-sm"
                                to={'/login'}
                            >
                                Enroll Now
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="text-gray-100 p-4 md:w-6/12 w-11/12">
                    <h2 className="text-2xl font-bold mb-4">Course Modules</h2>
                    <div className="space-y-4">
                        <Module title="Week 1-2: Introduction to Blockchain and Ethereum" topics={[
                            "What is Blockchain?",
                            "Introduction to Ethereum and Smart Contracts",
                            "Ethereum Virtual Machine (EVM) and Gas",
                            "Setting up Development Environment (Remix, Truffle, Ganache)"
                        ]} />
                        <Module title="Week 3-4: Solidity Basics" topics={[
                            "Solidity Overview and Syntax",
                            "Data Types and Variables",
                            "Functions and Modifiers",
                            "Events and Logging",
                            "Control Structures (if, for, while)"
                        ]} />
                        <Module title="Week 5: Solidity Advanced Concepts" topics={[
                            "Inheritance and Polymorphism",
                            "Abstract Contracts and Interfaces",
                            "Libraries and Reusable Code",
                            "Storage vs Memory vs Calldata"
                        ]} />
                        <Module title="Week 6: Solidity Security" topics={[
                            "Common Security Vulnerabilities (e.g., reentrancy, overflow)",
                            "Best Practices for Secure Smart Contract Development",
                            "Using OpenZeppelin for Security"
                        ]} />
                        <Module title="Week 7: Smart Contract Deployment and Interactions" topics={[
                            "Deploying Smart Contracts to Ethereum",
                            "Contract Address and Transactions",
                            "Interacting with Smart Contracts using Web3.js"
                        ]} />
                        <Module title="Week 8: Real-world Use Cases and Project" topics={[
                            "Decentralized Applications (DApps)",
                            "Token Standards (ERC-20, ERC-721)",
                            "Building a Simple Decentralized Voting Application",
                            "Final Project Presentation and Code Review"
                        ]} />
                    </div>
                    <div className="mt-8">
                        <h3 className="text-xl font-bold text-gray-200">Assessment and Evaluation:</h3>
                        <ul className="list-disc list-inside text-sm text-gray-300">
                            <li>Weekly Coding Assignments: 40%</li>
                            <li>Midterm Project (Simple Smart Contract): 20%</li>
                            <li>Final Project (Decentralized Voting App): 30%</li>
                            <li>Participation and Engagement: 10%</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Module({ title, topics }) {
    return (
        <div>
            <h3 className="text-xl font-semibold text-gray-200">{title}</h3>
            <ul className="list-disc list-inside ml-4 text-sm text-gray-300">
                {topics.map((topic, index) => (
                    <li key={index}>{topic}</li>
                ))}
            </ul>
        </div>
    );
}